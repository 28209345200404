<template>
  <div>
    <van-nav-bar title="待办任务" left-text="返回" @click-left="back" :right-text="user.companyId == '3092' ? '' : '新建任务'" @click-right="addTask()" fixed left-arrow />
    <div class="content">

    <van-sticky offset-top="1.22667rem">
        <!-- 全部任务/我执行的/我创建的 -->
        <van-tabs v-model="active" @click="activeClick">
            <van-tab title="全部任务" :name="0" v-if="viewAlltask"></van-tab>
            <van-tab title="我执行的" :name="1"></van-tab>
            <van-tab title="我创建的" :name="2"></van-tab>
        </van-tabs>
        <!-- 任务状态 -->
        <van-cell title="任务状态" :value="select_state" @click="select_state_show = true"></van-cell>
        <van-popup v-model="select_state_show" position="bottom">
            <van-picker
            show-toolbar
            :columns="select_state_array"
            @confirm="stateChange"
            @cancel="select_state_show = false;$forceUpdate();"/>
        </van-popup>
        <!-- 任务类型 -->
        <van-cell title="任务类型" :value="select_type" @click="select_type_show = true"></van-cell>
        <van-popup v-model="select_type_show" position="bottom">
            <van-picker
            show-toolbar
            :columns="select_type_array"
            @confirm="typeChange"
            @cancel="select_type_show = false;$forceUpdate();"/>
        </van-popup>
        <!-- 时间范围 -->
        <van-cell class="dateSelectCell">
            <template slot="title">
                <van-popover
                v-model="select_date_type_show"
                trigger="click"
                :actions="select_date_type_array"
                @select="dateTypeChange"
                placement="bottom-start">
                    <template slot="reference">
                        <span>{{select_date_type}}<van-icon name="arrow-down" size="10" style="margin-left:2px;" /></span>
                    </template>
                </van-popover>
            </template>
            <template slot="default">
                <div style="width:100%;height:100%" @click="select_date_show = true">{{select_date.length == 0 ? '请选择日期区间' : select_date[0] + ' 至 ' + select_date[1]}}</div>
            </template>
            <template slot="extra">
                <van-icon v-if="select_date.length != 0" name="close" style="line-height:0.64rem;position:relative;left:5px;" @click.stop="dateClear"/>
            </template>
        </van-cell>
        <van-calendar v-model="select_date_show" type="range" @confirm="dateChange" :min-date="new Date(2010,0,1)" :max-date="new Date(2030,11,31)" />
    </van-sticky>

        <div class="taskList" v-if="taskList.length != 0">
            <van-pull-refresh v-model="downLoading" @refresh="onDownRefresh">
                <!-- 任务列表 -->
                <van-list v-model="upLoading" :finished="upFinished" :immediate-check="false" :offset="100" finished-text="没有更多了" @load="onLoadList">
                    <van-cell v-for="item in taskList" :key="item.id">
                        <div style="line-height:0.8rem">任务名称：{{item.name}}</div>
                        <div style="line-height:0.8rem">项目名称：{{item.projectName}}</div>
                        <div style="line-height:0.8rem">起止时间：
                            <span v-if="item.startDate && item.endDate">{{(item.startDate ? item.startDate : ' - ') + '\u3000至\u3000' + (item.endDate ? item.endDate : ' - ')}}</span>
                            <span v-else></span>
                        </div>
                        <div class="task_button">
                            <van-button size="small" type="info" @click="toEditask(item.id)" v-if="authorityEditing || (user.id == item.createrId || user.id == item.inchargerId)">编辑</van-button>
                            <van-button size="small" type="primary" @click="taskStatus(item.id,0)" v-if="select_state == '进行中'">完成</van-button>
                            <van-button size="small" color="#e6a23c" @click="taskStatus(item.id,1)" v-else>重启</van-button>
                        </div>
                    </van-cell>
                </van-list>
            </van-pull-refresh>
        </div>
        <div v-else style="text-align:center;font-size:16px;font-weight:500;color:#999;width:100%;margin-top:16px">暂无数据</div>
        

    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            active: 1,
            user: JSON.parse(localStorage.userInfo),
            viewAlltask: false,
            select_state: '进行中',
            select_state_array: ['进行中','已完成'],
            select_state_show: false,

            select_type: '全部',
            select_type_show: false,
            select_type_array: ['全部','任务','里程碑','风险'],

            select_date: [],
            select_date_type: '开始时间',
            select_date_type_array: [{text:'开始时间'},{text:'截止时间'}],
            select_date_type_show: false,
            select_date_show: false,

            taskList: [],
            pageIndex: 1,
            total: 0,               // 列表总数据长度    // 页码，每页数据固定为20
            upLoading: false,       // 上拉加载
            upFinished: false,      // 上拉加载完毕
            downLoading: false,     // 下拉刷新
            listReLoading: false,   // 是否重新加载列表数据
            authorityEditing: false, // 编辑项目内任务权限

        }
    },
    mounted() {
        for(let i in this.user.functionList){
            if(this.user.functionList[i].name == '查看全部项目' || this.user.functionList[i].name == '查看全部任务'){
                this.viewAlltask = true
            }
            if(this.user.functionList[i].name == '管理全部项目'){
                this.viewAlltask = true;
                this.authorityEditing = true;//具备管理全部项目的权限，就可以编辑
            }
            if(this.user.functionList[i].name == '编辑项目内任务') {
                this.authorityEditing = true
            }
        }
        this.getList()
    },
    methods: {
        // 新建任务
        addTask() {
            let item = {
                addNew: true,
                id: null,
                groupId: '',
                stagesId: '',
                showOrNot: true
            }
            sessionStorage.setItem('taskId',JSON.stringify(item))
            this.$router.push("/editask");
        },
        back() {
            history.back();
        },
        onDownRefresh() {
            this.pageIndex = 1
            this.listReLoading = true
            this.upLoading = true
            this.upFinished = false // 不写这句会导致你上拉到底过后在下拉刷新将不能触发下拉加载事件
            this.getList()
        },
        onLoadList(){
            if(this.total <= this.taskList.length) {
                this.upFinished = true
                this.upLoading = false
                return
            }
            this.pageIndex += 1
            this.listReLoading = false
            this.getList()
        },


        toEditask(taskid){    // 跳转编辑任务
            sessionStorage.setItem('taskId',JSON.stringify({
                addNew: false,
                id: taskid,
                groupId: null,
                stagesId: null
            }))
            this.$router.push("/editask");
        },
        taskStatus(taskid,status){          // 改变任务状态
            this.$axios.post("/task/finish", {
                id: taskid,
                taskStatus: status
            }).then(res => {
                if(res.code == "ok") {
                    this.$toast.success('操作成功');
                    this.listReLoading = true
                    this.onDownRefresh()
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },

        formatDate(date) {
            let mon = date.getMonth() + 1
            return `${date.getFullYear()}-${mon<10?'0'+mon:mon}-${date.getDate()<10?'0'+date.getDate():date.getDate()}`;
        },
        activeClick(){  // 导航点击
            // console.log(this.active);
            this.listReLoading = true
            this.onDownRefresh()
        },
        stateChange(value,key){  // 任务状态筛选
            this.select_state = value
            this.select_state_show = false
            this.listReLoading = true
            this.onDownRefresh()
        },
        typeChange(value,key){   // 任务类型筛选
            // console.log(value,key);
            this.select_type = value
            this.select_type_show = false
            this.listReLoading = true
            this.onDownRefresh()
        },
        dateTypeChange(action,index){   // 开始时间/截止时间 切换
            // console.log(action,index);
            this.select_date_type = action.text
            if(this.select_date.length != 0){
                this.listReLoading = true
                this.onDownRefresh()
            }
        },
        dateChange(date){   // 时间区间筛选
            const [start, end] = date;
            this.select_date_show = false;
            this.select_date = [this.formatDate(start),this.formatDate(end)];
            // console.log(this.select_date,date);
            this.listReLoading = true
            this.onDownRefresh()
        },
        dateClear(){
            this.select_date = []
            this.listReLoading = true
            this.onDownRefresh()
        },

        getList(){  // 获取任务列表
            let parameter = {
                viewId: this.active,
                status: this.select_state == '进行中' ? 0 : 1,
                pageIndex: this.pageIndex,
                pageSize: 20
            }
            if(this.select_type != '全部'){
                if(this.select_type == '任务'){ parameter.type = 0 }
                if(this.select_type == '里程碑'){ parameter.type = 1 }
                if(this.select_type == '风险'){ parameter.type = 2 }
            }
            if(this.select_date.length != 0){
                parameter.dateType = this.select_date_type == '开始时间' ? 0 : 1
                parameter.startDate = this.select_date[0]
                parameter.endDate = this.select_date[1]
            }

            this.$axios.post("/task/listByPage", parameter)
            .then(res => {
                if(res.code == "ok") {
                    this.total = res.data.total
                    this.downLoading = false
                    this.upLoading = false
                    if(this.listReLoading){
                        this.taskList = res.data.records
                        if(document.documentElement){
                            document.documentElement.scrollTop = 0
                        }
                    }else{
                        for(let i in res.data.records){
                            this.taskList.push(res.data.records[i])
                        }
                    }
                    
                } else {
                    this.$toast.fail('获取失败');
                    this.downLoading = false
                    this.upLoading = false
                }
            }).catch(err=> {
                this.$toast.clear();
                console.log(err)
                this.downLoading = false
                this.upLoading = false
            });
        }

    },
}
</script>

<style lang="less" scoped>
.content{
    margin-top: 46px;
    // overflow: auto;
    .task_button{
        padding-top: .09rem;
        text-align: right;
        button{
            margin-left: .2667rem;
        }
    }
}
</style>